import { RouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import MainLayout from 'src/layout';
import DocsLayout from 'src/layouts/DocsLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import BottomNavigationLayout from 'src/layouts/BottomNavigationLayout';
import TopNavigationLayout from 'src/layouts/TopNavigationLayout';

import dashboardsRoutes from './dashboards';
import blocksRoutes from './blocks';
import applicationsRoutes from './applications';
import managementRoutes from './management';
import documentationRoutes from './documentation';
import baseRoutes from './base';
import Status404 from 'src/contentExamples/pages/Status/Status404';
import projectRoutes from './projects';
import authenticationRoutes from './authentication';
import policiesRoutes from './policies';
import settingsRoutes from './settings';
import helpRoutes from './help';
import Loader from './Loader';
import { lazy } from 'react';
import billingRoutes from './billing';

const Dashboard = Loader(lazy(() => import('src/contentExamples/dashboards/Crypto')));

const router: RouteObject[] = [
  ...authenticationRoutes,
  {
    path: 'policies',
    children: policiesRoutes,
  },

  // Documentation

  {
    path: 'docs',
    element: <DocsLayout />,
    children: documentationRoutes
  },

  // Boxed Sidebar Layout

  {
    path: '*',
    element: (
      <Authenticated>
        <MainLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      ...settingsRoutes,
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'projects',
        children: projectRoutes
      },
      {
        path: 'billing',
        children: billingRoutes,
      },
      {
        path: 'help',
        children: helpRoutes,
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
];

export default router;
