import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  styled,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AmplifySignInForm from './AmplifySignInForm';

import { useTranslation } from 'react-i18next';
import Logo from 'src/components/Logo/Logo';
import Scrollbar from 'src/components/Scrollbar';

import { ReactComponent as EthereumLogo } from 'src/assets/ethereum-logo.svg';
import { ReactComponent as SolanaLogo } from 'src/assets/solana-logo.svg';
import { ReactComponent as PolygonLogo } from 'src/assets/polygon-logo.svg';

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0 0 440px;
  }
  width: 100%;
  display: flex;
  align-items: center;
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
);

const CardImg = styled(Card)(
  ({ theme }) => `
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};
    position: absolute;

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);

function SignIn() {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('Sign in')}</title>
      </Helmet>
      <Content>
        <SidebarWrapper
          sx={{
            display: { xs: 'none', md: 'flex' }
          }}
        >
          <Scrollbar>
            <SidebarContent>
              <Logo />
              <Box mt={6}>
                <TypographyH1
                  variant="h1"
                  sx={{
                    mb: 7
                  }}
                >
                  {t('Unlock your ability to create NFTs')}
                </TypographyH1>
                <Box
                  sx={{
                    position: 'relative',
                    width: 300,
                    height: 120
                  }}
                >
                  <Tooltip arrow placement="top" title="Ethereum">
                    <CardImg
                      sx={{
                        width: 80,
                        height: 80,
                        left: 0,
                        top: -40
                      }}
                    >
                      <EthereumLogo width="40px" />
                    </CardImg>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="Solana">
                    <CardImg
                      sx={{
                        width: 90,
                        height: 90,
                        left: 90
                      }}
                    >
                      <SolanaLogo width="50px" />
                    </CardImg>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="Polygon">
                    <CardImg
                      sx={{
                        width: 100,
                        height: 100,
                        top: -30,
                        left: 200
                      }}
                    >
                      <PolygonLogo width="60px" />
                    </CardImg>
                  </Tooltip>
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    my: 3
                  }}
                >
                  {t(
                    'Let our automation help guide you through creation, management and deployment of your next NFT project.'
                  )}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Want hands-on help?')}
                </Typography>
                <Typography variant="subtitle1">
                  {t(
                    'We help projects with bespoke functionality that might not be available within the app'
                  )}
                  .&nbsp;
                  <Link component={RouterLink} to="/consulting">
                    Contact us for pricing
                  </Link>
                  .
                </Typography>
              </Box>
            </SidebarContent>
          </Scrollbar>
        </SidebarWrapper>
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Sign in')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
              <AmplifySignInForm />
              <Box my={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Don’t have an account, yet?')}
                </Typography>{' '}
                <Box display={{ xs: 'block', md: 'inline-block' }}>
                  <Link component={RouterLink} to="/register">
                    <b>Sign up here</b>
                  </Link>
                </Box>
              </Box>
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default SignIn;