import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Billing = Loader(lazy(() => import('src/screens/billing/Billing')));

const billingRoutes = [
  {
    path: '',
    element: <Billing />,
  },
];

export default billingRoutes