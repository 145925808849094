import { lazy } from 'react';
import Guest from 'src/components/Guest';
import Loader from './Loader';

const SignIn = Loader(
  lazy(() => import('src/screens/auth/signIn/SignIn'))
);

const SignUp = Loader(
  lazy(() => import('src/screens/auth/signUp/SignUp'))
);

const AccountVerification = Loader(
  lazy(() => import('src/screens/auth/verify/AccountVerification'))
);

const RecoverPassword = Loader(
  lazy(() => import('src/screens/auth/recoverPassword/RecoverPassword'))
);

const accountRoutes = [
  {
    path: 'sign-in',
    element: (
      <Guest>
        <SignIn />
      </Guest>
    )
  },
  {
    path: 'register',
    element: (
      <Guest>
        <SignUp />
      </Guest>
    )
  },
  {
    path: 'account-verify',
    element: (
      <Guest>
        <AccountVerification />
      </Guest>
    )
  },
  {
    path: 'recover-password',
    element: (
      <Guest>
        <RecoverPassword />
      </Guest>
    )
  },
];

export default accountRoutes;
