import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Settings = Loader(lazy(() => import('src/screens/settings/Settings')));
const Profile = Loader(lazy(() => import('src/screens/profile/Profile')));

const settingsRoutes = [
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'settings',
    element: <Settings />,
  },
];

export default settingsRoutes