import { lazy } from 'react';
import Guest from 'src/components/Guest';
import Loader from './Loader';

const Terms = Loader(lazy(() => import('src/screens/policies/terms/Terms')));
const PrivacyPolicy = Loader(lazy(() => import('src/screens/policies/privacy/Privacy')));

const policiesRoutes = [
  {
    path: 'terms',
    element: (
        <Guest>
            <Terms />
        </Guest>
    )
  },
  {
    path: 'privacy',
    element: (
        <Guest>
            <PrivacyPolicy />
        </Guest>
    )
  },
];

export default policiesRoutes;
