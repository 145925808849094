import type { ReactNode } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        name: 'Applications',
        icon: AnalyticsTwoToneIcon,
        link: '/boxed-sidebar/applications',
        items: [
          {
            name: 'Calendar',
            link: 'applications/calendar'
          },
          {
            name: 'File Manager',
            link: 'applications/file-manager'
          },
          {
            name: 'Jobs Platform',
            link: 'applications/jobs-platform'
          },
          {
            name: 'Mailbox',
            link: 'applications/mailbox/inbox'
          },
          {
            name: 'Messenger',
            link: 'applications/messenger'
          },
          {
            name: 'Projects Board',
            link: 'applications/projects-board'
          }
        ]
      }
    ]
  },
  {
    heading: 'Projects',
    items: [
      {
        name: 'Dashboard',
        icon: AnalyticsTwoToneIcon,
        link: '/dashboard'
      },
      {
        name: 'Projects',
        icon: AccountTreeTwoToneIcon,
        link: '/projects'
      },
      {
        name: 'Billing',
        icon: ReceiptTwoToneIcon,
        link: '/billing'
      },
    ]
  },
  {
    heading: 'Help',
    items: [
      {
        name: 'Settings',
        link: '/settings',
        icon: SettingsTwoToneIcon
      },
      {
        name: 'Help',
        icon: SupportTwoToneIcon,
        link: '/help',
        items: [
          {
            name: 'Documentation',
            icon: ArticleTwoToneIcon,
            link: '/help/docs',
          },
          {
            name: 'Contact support',
            icon: ContactSupportTwoToneIcon,
            link: '/help/contact',
          },
        ]
      }
    ]
  }
];

export default menuItems;
