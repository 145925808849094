import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import NFTUtilsLogo from '../../assets/nft-utils-logo.png';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 160px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};

        & img {
          width: 100%;
        }
`
);

const Logo: React.FC<{}> = () => {
  return (
    <LogoWrapper to="/">
      <img src={NFTUtilsLogo} alt="NFT Utils" />
    </LogoWrapper>
  );
}

export default Logo;
