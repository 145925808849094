import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import documentationRoutes from './documentation';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Contact = Loader(lazy(() => import('src/screens/help/contact/Contact')));

const helpRoutes = [
    {
        path: '',
        element: <Navigate to="contact" replace />
    },
    {
        path: 'docs',
        children: documentationRoutes,
    },
    {
        path: 'contact',
        element: <Contact />
    }
];

export default helpRoutes